import $ from 'jquery';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

if( $( '.amenities-map-section' ).length ){
  mapboxgl.accessToken = 'pk.eyJ1IjoiZmxpcHBkZXZzIiwiYSI6ImNrc2hxc2x0ZDF3eGQyb29hZDNndWhuZXcifQ.NLQN5AjdofpVMB8SBRgDgA';
  const mapLong = $( '#map' ).data( 'com-long' );
  const mapLat = $( '#map' ).data( 'com-lat' );
  const mapLink = $( '#map' ).data( 'link' );
  const mapCenter = [ mapLong, mapLat ];
  const mapCommunityTitle = $( '#map' ).data( 'com-name' )
  const mapZoom = 10.2;
  const mapCommunityHeadlineSize = 'headline-5'

  const markerZoom = 15;
  
  const mapInfo = document.querySelectorAll( '.map-info' )
  
  const map = new mapboxgl.Map( {
  
    container: 'map', // container ID
    style: 'mapbox://styles/flippdevs/cl396pof8000214qwswva31ek', // style URL
    center: mapCenter, // starting position [lng, lat]
    zoom: mapZoom, // starting zoom

  } );

  const mapLocatorDiv = document.createElement( 'div' )
  mapLocatorDiv.className = 'map-locator-logo';

  const mapLocatorPopUp = new mapboxgl.Popup( { offset: 2 } ) // add popups
    .setHTML(
      `<a href="${ mapLink }" class="${ mapCommunityHeadlineSize } map-box-heading center" target="_blank" aria-label="link to community map" rel="noopener noreferrer">${ mapCommunityTitle }</a>`
    )

  const mapLocatorMarker = new mapboxgl.Marker( mapLocatorDiv )
    .setLngLat( mapCenter )
    .setPopup( mapLocatorPopUp )
    .addTo( map );

  mapInfo.forEach( ( elem ) => {
    let markerNumber = $( elem ).find( '.map-number' )[0].textContent;
    let markerTitle = $( elem ).find( '.map-legend-item-title' )[0].textContent;
    let markerLat = $( elem ).data( 'lat' );
    let markerLon = $( elem ).data( 'lon' );

    // Create Marker Element
    const el = document.createElement( 'div' )
    el.className = 'marker'
    el.dataset.slug = elem.dataset.slug
    el.dataset.lat = elem.dataset.lat
    el.dataset.lon = elem.dataset.lon

    // Create Number for Marker Element
    const mapMarkerNumber = document.createElement( 'p' )
    mapMarkerNumber.className = 'map-marker-number'
    mapMarkerNumber.textContent = markerNumber;
    
    el.appendChild( mapMarkerNumber ) // Add to Marker Element

    const popUp = new mapboxgl.Popup( { offset: 2 } ) // add popups

    // Create a new marker.
    const marker = new mapboxgl.Marker(el)
    .setLngLat( [markerLon, markerLat] )
    .setPopup( popUp )
    .addTo( map );

    // Linking Legend Marker to Pop-Up on Map
    elem.addEventListener( 'click', ( e ) => {
      e.preventDefault();
      $( '.mapboxgl-popup' ).remove() // Remove all existing pop ups

      // Add popup
      const legendPopUp = new mapboxgl.Popup( { offset: 2, focusAfterOpen: false } )
      .setHTML(
        `<h2 class="legend-popup p map-box-heading text-black">${ markerTitle }</h2>`
      )
      .setLngLat([markerLon, markerLat])
      .addTo( map )

      map.flyTo({
        center:[markerLon, markerLat],
        zoom: markerZoom,
        essential: true
      })
    } )
  } )

  // Display Markers When Legend Items are Clicked
  const legendItem = document.querySelectorAll( '.map-legend-title-container' )
  const legendMarker = document.querySelectorAll( '.marker' )

  legendMarker.forEach( ( elem ) => {
    elem.addEventListener('click', function () {
      map.flyTo({
        center: [$( elem ).data( 'lon' ), $( elem ).data( 'lat' )],
        zoom: markerZoom,
      })
    })

    $( legendItem ).on( 'click', function() {

      map.flyTo({
        center: mapCenter,
        zoom: mapZoom,
      })

      if( $( this ).data().slug === $( elem ).data().slug ) {
        $( elem ).toggleClass( 'marker-toggle' )
        $( '.map-box-heading' ).parent().parent().remove()
      } else {
        $( elem ).removeClass( 'marker-toggle' )
      }
    } )
  } )

  // Disable Map Zoom on Scroll
  map.scrollZoom.disable();
  
  // Add zoom and rotation controls to the map.
  if( $( map._container).hasClass( 'amenities' ) ) {
    map.addControl(new mapboxgl.NavigationControl());
  }
}
import $ from 'jquery';
import slick from 'slick-carousel';

var $slickElement = $('[data-slick]');
let slickElement = document.querySelectorAll( '[data-slick]' )

if( $slickElement.length ) {
  slickElement.forEach( function( elem ) {

    // nav slider left
    $( elem ).parent().parent().find( '.slick-prev' ).on( 'click' ,function(e) {
      e.preventDefault();
      $( elem ).slick('slickPrev');
    });

    // nav slider right
    $( elem ).parent().parent().find( '.slick-next' ).on( 'click' ,function(e) {
      e.preventDefault();
      $( elem ).slick('slickNext');
    });

    // init slick
    $( elem ).slick({});

  } )
}


// Gallery Modal
if( $( '.gallery-wrapper' ).length ) {
  // gallery modal to go to the current img clicked for the clider
  $( '.gallery-wrapper > .gallery-image-wrapper' ).on( 'click', function( e ) {
    $( '.gallery-slideshow' ).slick( 'slickGoTo', $( this ).index() );
    $( '.gallery-modal-slide-nav' ).slick( 'slickGoTo', $( this ).index() );
  });
}
import $ from 'jquery';

var $modal = $( '[data-modal]' );
let $modalLotmap = $( '[data-modal-lotmap]' );

$( function() {
  function updateModal( hash ) {
    if ( hash == '' ) {
      $( 'body' ).removeClass( 'no-scroll' )
    }

    if (  hash && hash.startsWith( '#!' )  ) {
      if (  hash !== '#!'  ) {
        $( 'body' ).addClass( 'no-scroll' );
      } else {
        $( 'body' ).removeClass( 'no-scroll' );
      }
    }
  }

  if ( $modal.length ) {
    $( window ).on( 'hashchange', function() {
      updateModal( location.hash );
    });
    updateModal( location.hash );
  }
  //this is so the modal is initially hidden on page load
  setTimeout( () => {
    $( $modal ).addClass( 'visible' );
  }, 100 )

  if ( $modalLotmap.length ) {
    $( window ).on( 'hashchange', function() {
      updateModal( location.hash );
    });
    updateModal( location.hash );
  }
  //this is so the modal is initially hidden on page load
  setTimeout( () => {
    $( $modalLotmap ).addClass( 'visible' );
  }, 100 )
});

  // Form Validation
  const form = document.getElementById( 'mc-embedded-subscribe-form' )
  let dataInput = document.querySelectorAll( '[data-input]' )

  if( $( form ).length ) {
    form.addEventListener( 'change', () => {
      const submitButton = document.getElementById('mc-embedded-subscribe')

      if ( form.checkValidity() ) {
        submitButton.enable
        $( submitButton ).removeClass( 'disabled' )
      } else {
        submitButton.disabled
        $( submitButton ).addClass( 'disabled' )
      }
    } )
  }
  dataInput.forEach( ( elem ) => {
    elem.addEventListener( 'blur', () => {
      if ( elem.checkValidity() ) {
        $( elem ).removeClass( 'not-valid' )
      } else {
        $( elem ).addClass( 'not-valid' )
      }
    } )
  } )

/**
 * External Dependencies, short list is needed on every page
 */

 import './components/nav';
 import './components/anim';
import './components/modal';
 import './components/carousel';
 import './components/accordion';
 import './components/map';
 import LazyLoad from "vanilla-lazyload";

//One offs
// Lazyload images
var myLazyLoad = new LazyLoad();
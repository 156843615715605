import $ from 'jquery';
// a basic accordion. on load
$( function() {
  $( '.accordion .title' ).on( 'click', function(e) {
    e.preventDefault();

    var $this = $( this );
    var $accordion = $this.parent();
    var $content = $this.next();

    $accordion.parent().find( '.content' ).css( 'max-height', '0' );

    if ($accordion.hasClass( 'open' ) ) {
      $accordion.removeClass( 'open' );
    } else {
      $accordion.siblings().removeClass( 'open' );
      $accordion.addClass( 'open' );
      $content.css( 'max-height', $content[0].scrollHeight );
    }
  });
})